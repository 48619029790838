/***
*
*   AUTHENTICATION
*   Creates an auth provider to manage auth functions throughout
*   the application. Also defines a <PrivateRoute> component to
*   protect internal application routes from unauthenticated access.
*
**********/

import React from 'react'
import { Redirect, Route } from 'react-router-dom';

// auth context
export const AuthContext = React.createContext();

export class AuthProvider extends React.Component {

  constructor(props){

    super(props);

    const user = JSON.parse(localStorage.getItem('user'));

    this.user = {

      token: user ? user.token : null,
      subscription: user ? user.subscription : null,
      permission: user ? user.permission : null,
      name: user ? user.name : null,
      id: user ? user.id : null, //added id for segment
      email: user ? user.email : null //added email for segment

    }

    this.signin = this.signin.bind(this);
    this.signout = this.signout.bind(this);

  }

  permission(){

    return getPermission(this.user.permission);

  }

  signin(res){

    // store the user data
    if (res.data){

      localStorage.setItem('user', JSON.stringify(res.data));

      if (res.data.permission === 'master'){

        window.location = '/master';

      }
      else {
        window.analytics.identify(res.data); //segment
        window.location = '/projects'

      }
    }
  }

  signout(){

    localStorage.clear();
    window.location = '/signin'

  }

  updateSubscription(status){

    this.user.subscription = status;
    localStorage.setItem('user', JSON.stringify(this.user));

  }

  render(){

    return (

      <AuthContext.Provider value={{

        user: this.user,
        signin: this.signin,
        signout: this.signout,
        permission: this.permission,
        updateSubscription: this.updateSubscription

      }}

      {...this.props} />

    );
  };
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export const PrivateRoute = ({ render: Render, permission: Permission, ...rest }) => (

  <Route {...rest} render={ props => {

    let user = JSON.parse(localStorage.getItem('user'));

    if (user){

      let permissions = getPermission(user.permission);

      if (permissions[Permission]){
        if (!user.subscription){

          if (!window.location.pathname.includes('account/billing')){

            localStorage.setItem('BillingTabs', 1);
            return <Redirect to='/account/billing' />

          }
        }

        return <Render/>

      }
      else {

        return <Redirect to='/signin' />

      }
    }
    else {

      return <Redirect to='/signin' />;

    }
  }}/>
);

function getPermission(userType){

  switch (userType){

    case 'master':
    return { master: true, owner: true, admin: true, user: true };

    case 'owner':
    return { master: false, owner: true, admin: true, user: true };

    case 'admin':
    return { master: false, owner: false, admin: true, user: true };

    case 'user':
    return { master: false, owner: false, admin: false, user: true };

    default:
    return { master: false, owner: false, admin: false, user: false };

  }
}
