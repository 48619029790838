/***
*
*   GROUPS
*   Lists the groups for the current project
*
**********/

import React from 'react';
import Axios from 'axios';
import { ViewContext, Header, Card, Button, Table, Actions, IconButton,
  UpdateTableRow, DeleteTableRow, ResetModalForm, Select,
  ProjectNav, Loader, BlankSlateMessage } from 'components/lib';

export class Groups extends React.Component {

  constructor(props){

    super(props);

    const url = window.location.href;
    this.slug = url.substring(url.indexOf('projects/')+9, url.indexOf('/groups'));

    this.state = {

      loading: false,
      groups: {

        header: null,
        body: null,

      }
    }

    this.projectId = localStorage.getItem('projectId');

    this.editGroupForm = {
      name: {
        label: 'Group Name',
        type: 'text',
        required: true,
        errorMessage: 'Please provide a group name'
      },
      group_type: {
        label: 'Group Type',
        type: 'select',
        required: true,
        options: [
          { value: 'Company', name: 'Company' },
          { value: 'Location', name: 'Location' },
          { value: 'Trade', name: 'Trade' },
          { value: 'Other', name: 'Other' }          
      ],
        errorMessage: 'Please select type'
      },
      id: {
        type: 'hidden',
        id: null
      },
      project_id: {
        type: 'hidden',
        value: localStorage.getItem('projectId')
      }
    }

    this.editGroupTForm = {
      
      group_type: {
        label: 'Group Type',
        type: 'select',
        required: true,
        options: [
          { value: 'Company', name: 'Company' },
          { value: 'Location', name: 'Location' },
          { value: 'Trade', name: 'Trade' },
          { value: 'Other', name: 'Other' }              
      ],
        errorMessage: 'Please select type'
      },
      id: {
        type: 'hidden',
        id: null
      },
      project_id: {
        type: 'hidden',
        value: localStorage.getItem('projectId')
      }
    }

    this.editGroupNForm = {
      
      name: {
        label: 'Group Name',
        type: 'text',
        required: true,
        errorMessage: 'Please provide a group name'
      },
      id: {
        type: 'hidden',
        id: null
      },
      project_id: {
        type: 'hidden',
        value: localStorage.getItem('projectId')
      }
    }

    this.deleteGroupForm = {
      id: {
        type: 'hidden',
        value: null
      }
    }

    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.rename = this.rename.bind(this);

  }

  async componentDidMount(){
    window.analytics.page('Groups'); //segment analytics event
    try {

      this.setState({ loading: true });
      const res = await Axios.get('/api/project/' + this.slug + '/groups');

      this.setState({

        loading: false,
        groups: {

          ...this.state.groups,
          body: res.data.groups

        }
      });
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  create(){

    ResetModalForm(this.editGroupForm);
    this.editGroupForm.project_id.value = this.projectId;

    this.context.showModal({

      title: 'Create Group',
      form: this.editGroupForm,
      buttonText: 'Create',
      url: '/api/group',
      method: 'POST'

    }, (form, response) => {

      let rows = this.state.groups.body;
      rows.unshift(response.group);

      this.setState({
        groups: {
          ...this.state.groups,
          body: rows
        }
      });

      // append table row
      this.context.showNotification('Group created', 'success', true);

    });
  }

  edit(data){

    this.editGroupTForm.id.value = data.id;

    // update the group
    for (let key in this.editGroupTForm)
      this.editGroupTForm[key].value = data[key]

    this.context.showModal({

      title: 'Update Group Type',
      form: this.editGroupTForm,
      buttonText: 'Update',
      url: '/api/group',
      method: 'PUT'

    }, (res) => {

      this.context.showNotification(data.name + ' was updated', 'success', true);
      UpdateTableRow(this.state.groups.body, res, data.id);

    });
  }

  rename(data){

    this.editGroupNForm.id.value = data.id;

    // update the group
    for (let key in this.editGroupNForm)
      this.editGroupNForm[key].value = data[key]

    this.context.showModal({

      title: 'Update Group Type',
      form: this.editGroupNForm,
      buttonText: 'Update',
      url: '/api/group',
      method: 'PUT'

    }, (res) => {

      this.context.showNotification(data.name + ' was updated', 'success', true);
      UpdateTableRow(this.state.groups.body, res, data.id);

    });
  }

  delete(data){

    // delete the group
    this.deleteGroupForm.id.value = data.id

    this.context.showModal({

      title: 'Delete Group',
      form: this.deleteGroupForm,
      buttonText: 'Delete',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: '/api/group',
      method: 'DELETE'

    }, () => {

      this.context.showNotification(data.name + ' was deleted', 'success', true);
      DeleteTableRow(this.state.groups.body, data.id);

    });
  }

  render(){

    return(

      <React.Fragment>
        <Header title={ this.slug + ' / ' + 'Groups'} >
          <Button text='New Group' action={ this.create } icon='users' />
        </Header>

        <ProjectNav />

        { this.state.loading ?

          <Loader text='Loading groups' /> :

          <React.Fragment>
          { this.state.groups.body &&
            this.state.groups.body.length ?

            <Card >
              <Table data={ this.state.groups }>

                <Actions>
                  <IconButton image='users' color='dark' action={ this.edit }/>
                  <IconButton image='trash' color='dark' action={ this.delete }/>
                </Actions>

              </Table>
            </Card>

            :

            <BlankSlateMessage
              title='Create a Group'
              text={ "You haven't created any groups yet. Would you like to create one now?" }
              buttonText='Create Group'
              action={ this.create }
            />

          }
          </React.Fragment>
        }
      </React.Fragment>

    );
  }
}

Groups.contextType = ViewContext;
