/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   loading: true/false
*   title: title of the view
*
**********/

import React from 'react';

import { AuthContext, AppNav } from 'components/lib';
import '../layout.scss';
import './app.scss';

export class AppLayout extends React.Component {

  render(){

    // remove the background color applied from the homepage
    document.body.classList.remove('color-bg');

    // create the css class
    let cssClass = 'app with-sidebar with-bottom-nav';
    if (this.props.loading) cssClass = ' hide';

    return (

      <>
      <AppNav
        type='slideout'
        items={[
          { label: 'Projects', link: '/projects', hasSubNav: true },
          { label: 'Users', link: '/users', hasSubNav: true },
          { label: 'Account', link: '/account', hasSubNav: true }
        ]}
      />

      <main className={ cssClass }>

        { <this.props.children/> }

      </main>
      </>

    );
  }
}

AppLayout.contextType = AuthContext;
