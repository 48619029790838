/***
*
*   PROFILE
*   Enables the user to update their name and email address
*   or close their account.
*
**********/

import React from 'react';
import Axios from 'axios';

import { AuthContext, ViewContext, Header, Form, Card,
  AccountNav, Button, History } from 'components/lib';

export class Profile extends React.Component {

  constructor(props){

    super(props);
    this.state = {

      loading: false,

      profile: {
        name: {
          label: 'Name',
          type: 'text',
          required: true,
          errorMessage: 'Please enter your name',
        },
        email: {
          label: 'Email address',
          type: 'email',
          required: true,
          errorMessage: 'Please enter your email address'
        }
      },
    }

    this.closeAccount = this.closeAccount.bind(this);

  }

  async componentDidMount(){
    window.analytics.page('Profile'); //segment analytics event
    // get the data

    try {

      this.setState({ loading: true });

      let res = await Axios.get('/api/user');

      this.setState({

        loading: false,

        profile: {
          ...this.state.profile,

          name: {
            ...this.state.profile.name,
            value: res.data.user.name
          },
          email: {
            ...this.state.profile.email,
            value: res.data.user.email
          }
        }
      });
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  closeAccount(){

    this.context.showModal({

      title: 'Close Your Account',
      form: this.closeAccount,
      buttonText: 'Close Account',
      url: '/api/account',
      method: 'DELETE',
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {

      // destory user
      localStorage.clear();
      History.push('/signup');

    });
  }

  render(){

    return (
      <>
      <Header title='Your Account' />
      <AccountNav />

      <AuthContext.Consumer>
        {(context) => (

          <Card
            title="Edit Your Profile Information"
            loading={ this.state.loading }>

            <Form
              className='restrict-width'
              url='/api/account'
              method='PUT'
              data={ this.state.profile }
              buttonText='Save'
            />

            <br/>

            { context.permission().owner &&
              <Button
                className='btn-text'
                color='red'
                text='Close Your Account'
                action={ this.closeAccount }
              />
            }

          </Card>

        )}
      </AuthContext.Consumer>
      </>
    );
  }
}

Profile.contextType = ViewContext;
