import React from 'react';
import { Button, History } from 'components/lib';

export class Body extends React.Component {

  render(){

    if (this.props.data){
      if (this.props.data.length){
        return (

          <tbody>
          { this.props.data.map((row, index) => {

            return (
              <Row
                key={ index }
                data={ row }
                badge={ this.props.badge }
                showIds={ this.props.showIds }
                actions={ this.props.actions }
              />
            );

          })}
          </tbody>
        );
      }
    }

    return (
      <tbody>
        <tr>
          <td colSpan='10'>No results found</td>
        </tr>
      </tbody>
    );
  }
}

export class Row extends React.Component {

  render(){

    // inject the actions
    if (this.props.actions)
      this.props.data.actions = this.props.actions;

    return(
      <tr data-id={ this.props.data.id }>
        { Object.keys(this.props.data).map((cell, index) => {

          const value = this.props.data[cell];

          // ignore slugs
          if (cell === 'slug')
            return false;

          // return empty row
          if (value === null || value === undefined || value === false)
            return <td key={ index }></td>

          // render the ids?
          if (cell === 'id' && !this.props.showIds)
            return false;

          // does this cell have a badge?
          if (this.props.badge){
            if (cell === this.props.badge.col){

              return (
                <td key={ index }>
                <span className={ this.props.badge.color ? 'badge ' + this.props.badge.color : 'badge' }>
                { value }
                </span>
              </td>
              );

            }
          }

          // is this a multi row cell?
          if (Array.isArray(value)){
            return (
              <td key={ index }>
                { value.map((row, index) => {

                  return (row.url ?

                    <LinkButton
                      key={ index }
                      className={ index === 0 ? 'cell-row cell-title' : 'cell-row' }
                      label={ row.label }
                      url={ row.url }
                      id={ this.props.data.id }
                      slug={ this.props.data.slug }
                    />

                    :

                    <React.Fragment key={ index }>
                      <div key={ row.label }
                        className={ index === 0 ? 'cell-row cell-title' : 'cell-row' }>

                        { index === 0 &&
                          <div className='cell-label'>{ cell.replace('_', ' ') }</div>
                        }
                        { row.label }
                      </div>
                    </React.Fragment>

                  )
                })}
              </td>
            )
          }

          // create the actions
          if (cell === 'actions'){

            let buttons = [];

            if (this.props.data.id){
              value.props.children.map((action, index) => {

                let button =  React.cloneElement(action, { params: this.props.data, key: index });
                buttons.push(button);
                return false;

              });

              return <td key={ index } className='actions'>{ buttons }</td>

            }
            else {
              return <td key={ index } className='actions'>{ value }</td>
            }
          }

          if (value.url){

            return (
              <td key={ index }>
                <LinkButton
                  label={ value.label }
                  url={ value.url }
                  id={ this.props.data.id }
                  slug={ this.props.data.slug }
                />
              </td>
            );
          }

          // standard cell
          return (
            <td key={ index }>
            <div className='cell-label'>{ cell.replace('_', ' ') }</div>
            { value }
            </td>
          )

        })}
      </tr>
    );
  }
}

class LinkButton extends React.Component {

  render(){
    return (
      <Button
        text={ this.props.label }
        className={ this.props.className ? this.props.className + ' btn-text' : 'btn-text' }
        action={ event => {

          localStorage.setItem('projectId', this.props.id);
          localStorage.setItem('slug', this.props.slug);
          History.push(this.props.url);

        }}
      />
    );
  }
}
