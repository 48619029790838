/***
*
*   BUTTON
*   Standard button with callback function.
*
*   PROPS
*   text: button label
*   action: callback function executed on click
*   params: object passed to the callback function (optional)
*   color: red/blue (default: green)
*   className: apply a custom css class (optional)
*
**********/

import React from 'react';
import { Icon } from 'components/lib';
import './button.scss';

export class Button extends React.Component {

  render(){

    let cssClass = '';

    if (this.props.className && this.props.className.indexOf('btn-text') > -1) cssClass += 'btn-text';
    else cssClass = 'btn';

    if (this.props.color) cssClass += ' ' + this.props.color;
    if (this.props.className) cssClass += ' ' + this.props.className;

    if (this.props.icon){

      return (
        <div className='btn with-icon'>

          <Icon
            image={ this.props.icon }
            color='white'
          />

          <button
            onClick={
              event => {

                this.props.action &&
                this.props.action(this.props.params)

              }}>

              { this.props.text }

          </button>
        </div>
      );

    }

    return (

      <button
        className={ cssClass }
        onClick={
          event => {

            this.props.action &&
            this.props.action(this.props.params);

          }
        }>

        { this.props.text }

      </button>

    );
  }
}
