import React from 'react';

import { Label } from '../label/label';
import { Error } from '../error/error';

import './input.scss';

export class TextInput extends React.Component {

  constructor(props){

    super(props);

    this.defaultErrorMessage = "Please enter a value"
    this.validate = this.validate.bind(this);

  }

  validate(event, callback){

    let value, valid = undefined;
    event ? value = event.target.value : value = '';

    // input is required and value is blank
    if (this.props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!this.props.required && value === '')
      valid = true;

    if (this.props.required && value !== '')
      valid = true;

    // update the parent form
    callback(this.props.name, value, valid);

  }

  render(){

    let cssClass = 'textbox';
    let showError = false;

    if (this.props.valid === false) showError = true;
    if (this.props.valid === true) cssClass += ' success'
    if (this.props.className) cssClass += ' ' + this.props.className;

    return(

      <>
      <Label
        text={ this.props.label }
        required={ this.props.required }
        for={ this.props.name }
      />

      { this.props.type === 'textarea' ?

        <textarea
          id={ this.props.name }
          name={ this.props.name }
          className={ showError ? cssClass + ' error' : cssClass }
          value={ this.props.value }
          placeholder={ this.props.placeholder }
          onChange={ event => this.props.onChange(this.props.name, event.target.value, undefined) }
          onBlur={ event => this.validate(event, this.props.onChange) }
        >
        </textarea>

        :

        <input
          type='text'
          id={ this.props.id }
          name={ this.props.name }
          className={ showError ? cssClass + ' error' : cssClass }
          value={ this.props.value }
          placeholder={ this.props.placeholder }
          onChange={ event => this.props.onChange(this.props.name, event.target.value, undefined) }
          onBlur={ event => this.validate(event, this.props.onChange) }
        />
      }

      { showError && <Error message={ this.props.errorMessage ?
        this.props.errorMessage : this.defaultErrorMessage }/>
      }

      </>
    );
  }
}
