/***
*
*   MESSAGE
*   Colored feedback message with optional call to action.
*
*   PROPS
*   type - info/success/warning/error
*   title - descriptive string
*   text - string
*   closable - true/false to determine if the user can close the message
*   buttonText - text for the cta button (optional)
*   buttonLink - url link for the button (optional)
*
**********/

import React from 'react';
import { Button, Icon, IconButton, History } from 'components/lib';
import './message.scss';

export class Message extends React.Component {

  constructor(){

    super();

    this.icons = {

      info: 'info',
      success: 'check',
      warning: 'alert-triangle',
      error: 'alert-octagon'

    };

    this.colors = {

      info: '#73B0F4',
      success: '#8CC57D',
      warning: '#F0AA61',
      error: '#d95565'

    }

    this.state = {

      closed: false

    }

    this.close = this.close.bind(this);

  }

  close(){

    this.setState({ closed: true });

  }

  render(){

    if (this.state.closed)
      return false;

    let color = '#FFF';
    let cssClass = 'message ';
    let icon = 'info';

    if (this.props.type){

      cssClass += this.props.type;
      icon = this.icons[this.props.type];
      color = this.colors[this.props.type];

    }
    else {

      cssClass += 'info';

    }

    return (

      <div className= { cssClass }>

        <Icon color={ color } image={ icon } />

        { this.props.closable &&
          <IconButton
            image='x'
            size='20'
            className='btn-close'
            action={ this.close }
          />
        }

        <section className="content">

          <h1>{ this.props.title }</h1>
          <p>{ this.props.text }</p>

          { this.props.buttonLink &&
            <Button
              text={ this.props.buttonText }
              action={ event => History.push(this.props.buttonLink) }
            />
          }

       </section>

      </div>
    );
  }
}
