/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React from 'react';
import { AuthContext, ViewContext, Form, Card, Link } from 'components/lib';

export class Signin extends React.Component {

  constructor(props){

    super(props);

    this.signinForm = {
      email: {
        label: 'Email',
        type: 'email',
        required: true,
      },
      password: {
        label: 'Password',
        type: 'password',
        required: true
      },
      forgotpassword: {
        type: 'link',
        url: '/forgotpassword',
        text: 'Forgot your password?'
      }
    }
  }

  render(){
    return(

      <>
      <h1>Sign In to Rafters</h1>

      <Card>
      <AuthContext.Consumer>
        {(context) => {

          return <Form
            data={ this.signinForm }
            url='/api/user/auth'
            method='POST'
            buttonText='Sign In'
            callback={ context.signin }
          />

        }}
      </AuthContext.Consumer>

      <span>Don't have an account? <Link url="/signup" text="Sign Up"/></span>
      </Card>
      <span>
      <a href='https://www.iubenda.com/privacy-policy/78277502/full-legal'>Privacy Policy  |</a> 
      <a href='https://www.iubenda.com/privacy-policy/78277502/cookie-policy'>  Cookie Policy  |</a> 
      <a href='https://www.iubenda.com/terms-and-conditions/78277502'>  Terms and Conditions </a> 
      </span>

      </>

    );
  }
}

Signin.contextType = ViewContext;
