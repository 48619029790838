/***
*
*   ACCOUNT NAV
*   Pre-built account sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners.
*
*   PROPS
*   cols: number of columns (default: 2)
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import './sub.scss';

export class AccountNav extends React.Component {

  render(){
    return(
      <AuthContext.Consumer>
        {(context) => (

          <nav className="subnav">

            <NavLink exact to='/account' activeClassName='active'>Profile</NavLink>
            <NavLink exact to='/account/password' activeClassName='active'>Password</NavLink>

            { context.permission()['owner'] &&

              <>
              <NavLink exact to='/account/billing' activeClassName='active'>Billing</NavLink>
              </>

            }
          </nav>
        )}
      </AuthContext.Consumer>
    );
  }
}
