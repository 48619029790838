/***
*
*   PROJECT NAV
*   Pre-built account sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners.
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import './sub.scss';

export class ProjectNav extends React.Component {

  constructor(props){

    super(props);
    this.link = {

      notifications: '/projects/' + localStorage.getItem('slug') + '/notifications',
      groups: '/projects/' + localStorage.getItem('slug') + '/groups',
      contacts: '/projects/' + localStorage.getItem('slug') + '/contacts',

    }
  }

  render(){
    return(
      <nav className="subnav">

        <NavLink exact to={ this.link.notifications } activeClassName='active'>Notifications</NavLink>
        <NavLink exact to={ this.link.groups } activeClassName='active'>Groups</NavLink>
        <NavLink exact to={ this.link.contacts } activeClassName='active'>Contacts</NavLink>

      </nav>
    );
  }
}
