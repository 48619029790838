/***
*
*   EXTERNAL LAYOUT
*   Layout for external pages, such as 404
*
**********/

import React from 'react';

import { Logo } from 'components/lib';
import './external.scss'
import '../layout.scss';

export class ExternalLayout extends React.Component {

  render(){

    document.body.classList.add('color-bg');

    return (

      <main class='external'>

        <Logo/>
        <this.props.children/>

      </main>
    );
  }
}
