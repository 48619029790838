/***
*
*   BADGE
*   Inline text badge with background color.
*
*   PROPS
*   text: string to be displayed
*   color: blue/red/green/orange (default: purple)
*
**********/

import React from 'react';
import './badge.scss';

export class Badge extends React.Component {

  render(){
    return (

      <span className={ this.props.color ? 'badge ' + this.props.color : 'badge' }>
        { this.props.text }
      </span>

    );
  }
}
