import React from 'react';
import Select from 'react-select';

import { Label } from '../label/label';
import { Error } from '../error/error';

import './multiselect.scss';

export class MultiSelect extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      selected: this.props.default ? this.props.default : null

    }

    this.defaultErrorMessage = "Please select an option"
    this.select = this.select.bind(this);

  }

  select(option){

    this.setState({ selected: option });
    this.props.onChange(this.props.name, option, undefined);

  }

  render(){

    let cssClass = 'multiselect';
    let showError = false;

    if (this.props.valid === false) showError = true;
    if (this.props.valid === true) cssClass += ' success'
    if (this.props.className) cssClass += ' ' + this.props.className;

    return (

      <>
      { this.props.label &&
        <Label
          text={ this.props.label }
          required={ this.props.required }
          for={ this.props.name }
        />
      }

      <Select
        className={ showError ? cssClass + ' error' : cssClass }
        classNamePrefix='multiselect'
        value={ this.state.selected }
        onChange={ this.select }
        isMulti
        placeholder={ this.props.placeholder ? this.props.placeholder : 'Select' }
        options={ this.props.options }
     />

     { showError &&
       <Error message={ this.props.errorMessage ?
         this.props.errorMessage : this.defaultErrorMessage }/>
     }

    </>

    );
  }
}
