/***
*
*   FORM
*   Self-validating form that accepts an object for construction
*   Read the full documentation on object formatting
*   https://docs.usegravity.app/ui/form
*
*   PROPS
*   data: the object containing your form data
*   callback: function to be executed on successful submit
*   url: url to send the form to (optional)
*   method: HTTP request type
*   redirect: url to redirect to after a successful submit (optional)
*   buttonText: submit button text
*   cancel: true/false to toggle a cancel button (optional)
*
**********/

import React from 'react';
import Axios from 'axios';

import { FormHeader } from './header'
import { TextInput } from './input/text';
import { NumberInput } from "./input/number";
import { EmailInput } from "./input/email";
import { URLInput } from "./input/url";
import { PhoneInput } from "./input/phone";
import { PasswordInput } from "./input/password";
import { HiddenInput } from "./input/hidden";
import { CardInput } from './input/card';
import { Select } from "./select/select";
import { Switch } from './switch/switch';
import { MultiSelect } from './multiselect/multiselect';
import { Fieldset } from "./fieldset/fieldset";
import { Link, LoadingButton, ViewContext, History } from 'components/lib';

import './form.scss';
import { injectStripe } from 'react-stripe-elements';

class Form extends React.Component {

  constructor(props){

    super(props);

    // set initial input states
    let form = this.props.data;
    for (let input in form){

      // input is an array
      if (form[input].length){
        for (let sub in form[input]){
          for (let key in form[input][sub]){

            if (!this.props.data[input][sub][key].value)
              form[input][sub][key].value = '';

            form[input][sub][key].valid = undefined;

          }
        }
      }
      else {

        // using single input
        if (!this.props.data[input].value)
          form[input].value = '';

        form[input].valid = undefined

      }
    }

    this.formElements = {
      text: TextInput,
      email: EmailInput,
      number: NumberInput,
      url: URLInput,
      hidden: HiddenInput,
      phone: PhoneInput,
      password: PasswordInput,
      creditcard: CardInput,
      radio: Fieldset,
      select: Select,
      checkbox: Fieldset,
      selector: Fieldset,
      switch: Switch,
      header: FormHeader,
      link: Link,
      multiselect: MultiSelect
    }

    this.state = {

      valid: false,
      form: form,
      loading: false

    }

    this.processCreditCard = false;
    this.update = this.update.bind(this);
    this.validate = this.validate.bind(this);
    this.submit = this.submit.bind(this);

  }

  update(input, value, valid){

    // update the input value and valid state
    let state = Object.assign({}, this.state);

    // input is part of an array
    if (input.indexOf('[') > 1){

      const parent = input.substring(0, input.indexOf('['));
      const index = input.substring(input.indexOf('[') + 1, input.indexOf(']'));
      const substr = input.substring(input.indexOf(']') +1 );
      const key = substr.substring(substr.indexOf('[') + 1, substr.indexOf(']'));

      if (state.form[parent]){

        state.form[parent][index][key].value = value;
        state.form[parent][index][key].valid = valid;

      }
    }
    else {

      // single input
      state.form[input].value = value;
      state.form[input].valid = valid;

    }

    this.setState(state);

  }

  validate(){

    // loop over each input and check it's valid
    // show error if input is requried and value
    // is blank, input type validation will then
    // be executed on input blur

    let errors = [];
    let state = Object.assign({}, this.state);

    // loop the inputs
    for (let input in state.form){

      // validate credit card
      if (input === 'token'){
        if (state.form.token.value.error){

          state.form.token.valid = false
          errors.push(false);

        }
        else {

          state.form.token.valid = true;

        }
      }

      // validate input array
      else if (state.form[input].length){
        for (let i = 0; i < state.form[input].length; i++){
          for (let key in state.form[input][i]){

            let sub = state.form[input][i][key];
            if (sub.required && sub.value === ''){

              sub.valid = false;
              errors.push(false);

            }
          }
        }
      }

      // validate single input
      else {

        let inp = state.form[input];

        if (
          (inp.required && inp.value === null) ||
          (inp.required && inp.value === undefined) ||
          (inp.required && inp.value === '') ||
          (inp.required && inp.value === 'unselected')
        ){

          inp.valid = false;
          errors.push(false);

        }
      }
    }
    if (errors.length){

      // form isn't valid
      state.valid = false;
      this.setState(state);
      return false;

    }
    else {

      // form is valid
      return true;

    }
  }

  async submit(e){

    // submit the form
    e.preventDefault();
    let state = Object.assign({}, this.state);

    // create the credit card token
    if (this.processCreditCard){

      state.form.token.value =
      await this.props.stripe.createToken({ type: 'card' });

    }

    this.setState(state);
    state = Object.assign({}, this.state);

    // is the form valid?
    if (!this.validate())
      return false;

    // form is valid
    // process fields before submit
    // so only the values are sent to the server
    let data = {};
    state.valid = true;

    // loop each input
    for (let input in state.form){
      if (input === 'token'){

        // procress credit card
        data[input] = state.form[input].value.token;

      }
      else if (state.form[input].length){

        // process input array
        data[input] = [];

        for (let i = 0; i < state.form[input].length; i++){

          data[input].push({});
          for (let key in state.form[input][i])
            data[input][i][key] = state.form[input][i][key].value;

        }
      }
      else if (input !== 'header'){

        // process single input & ignore headers
        data[input] = state.form[input].value;

      }
    }

    // submit the form or execute callback
    if (!this.props.url){

      if (this.props.callback)
        this.props.callback(data);

      return false;

    }

    try {

      // send the form
      this.setState({ loading: true });

      let res = await Axios({

        method: this.props.method,
        url: this.props.url,
        data: data,

      });

      // check for 2-factor payment requirement
      if (res.data.requires_payment_action){

        const stripeRes =
        await this.props.stripe.handleCardPayment(res.data.client_secret);

        if (stripeRes.error){

          this.setState({ loading: false });
          this.context.handleError(stripeRes.error.message);
          return false;

        }
        else {

          // re-send the form
          data.stripe = res.data;
          res = await Axios({

            method: this.props.method,
            url: this.props.url,
            data: data

          });
        }
      }

      // finish loading
      this.setState({ loading: false });

      // close the modal
      this.context.hideModal(false, res.data);

      // callback?
      if (this.props.callback)
        this.props.callback(res);

      // redirect?
      if (this.props.redirect)
        History.push(this.props.redirect);

      // success notification
      if (res.data.message)
        this.context.showNotification(res.data.message, 'success', true);

    }
    catch (err){

      // handle error
      this.setState({ loading: false });
      this.context.hideModal(true);

      // show error on input
      if (err.response){
        if (err.response.data){
          if (err.response.data.inputError){

            const input = err.response.data.inputError;
            this.state.form[input].valid = false;
            this.state.form[input].errorMessage = err.response.data.message;

          }
          else {

            // general errors handled by view
            this.context.handleError(err);

          }
        }
      }
    }
  }

  cancel(event){

    event.preventDefault();
    this.props.cancel();

  }

  render(){

    let cssClass = null;
    let inputsToRender = [];
    if (this.props.className) cssClass = this.props.className;
    if (this.state.loading) cssClass += ' loading';

    // loop each key (input) in the form
    // pass the data into the inputsToRender array
    // then render the inputs after the loop
    if (this.state.form){

      Object.keys(this.state.form).map(name => {

        // get the values for this input
        const data = this.state.form[name];

        if (!data.length){

          // render a single input
          data.name = name;
          inputsToRender.push(data);

        }
        else {

          // render an array of inputs
          for (let i = 0; i < data.length; i++){
            for (let key in data[i]){

              const input = data[i][key];
              input.name = 'plans[' + i + '][' + key + ']';
              inputsToRender.push(input);

            }
          }
        }

        return false;

      });
    }

    // render the form
    return(

      <form
        action={ this.props.action }
        method = { this.props.method }
        onSubmit={ this.submit }
        className={ cssClass }
        noValidate>

        { inputsToRender.map(input => {

          if (!input.type) input.type = 'text';
          if (input.type === 'creditcard')
            this.processCreditCard = true;

          const Input = this.formElements[input.type];

          return (
            <Input
             key={ input.name }
             type={ input.type }
             form={ this.props.name }
             label={ input.label }
             className={ input.class }
             name={ input.name }
             value={ input.value }
             required={ input.required }
             valid={ input.valid }
             min={ input.min }
             max={ input.max }
             options={ input.options }
             default={ input.default }
             url={ input.url }
             text={ input.text }
             helper={ input.helper }
             title={ input.title }
             handleLabel={ input.handleLabel }
             placeholder={ input.placeholder }
             errorMessage={ input.errorMessage }
             onChange={ this.update }
            />
          );
        })}

        { this.props.cancel &&
          <button className="btn btn-outline" onClick={ event => this.cancel(event) }>Cancel</button> }

        <LoadingButton
          loading={ this.state.loading }
          text={ this.props.buttonText }
        />

      </form>

    );
  }
}

Form.contextType = ViewContext;

const PaymentForm = injectStripe(Form);

// export two versions of the form
// 1. is a standard form
// 2. is wrapped in a stripe context
export { Form, PaymentForm }
