/***
*
*   MASTER LAYOUT
*   The master dashboard layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   loading: true/false
*   title: title of the view
*
**********/

import React from 'react';
import { AuthContext, Header, AppNav } from 'components/lib';

export class MasterLayout extends React.Component {

  render(){

    document.body.classList.remove('color-bg');

    let cssClass = 'app master with-sidebar with-bottom-nav';
    if (this.props.loading) cssClass = ' hide';

    return (

      <>
      <AppNav
        type='slideout'
        items={[
          { label: 'Dashboard', link: '/master' },
          { label: 'Accounts', link: '/master/accounts' },
          { label: 'Users', link: '/master/users' }
        ]}
      />

      <main className={ cssClass }>

        <Header title={ this.props.title }/>
        { <this.props.children/> }

      </main>
      </>

    );
  }
}

MasterLayout.contextType = AuthContext;
