/***
*
*   HOVER NAV
*   Reveals a nav when the user hovers over a hotspot.
*   Items are rendered as children, revealed is achieved with CSS.
*
*   PROPS
*   color: dark or light (default: dark)
*   label: the hotspot text
*   align: left or right
*
**********/

import React from 'react';
import './hover.scss';

export class HoverNav extends React.Component {

  render(){

    let cssClass = 'hover-nav';

    if (this.props.color) cssClass += ' ' + this.props.color
    else cssClass += ' dark';

    if (this.props.align) cssClass += ' align-' + this.props.align;
    else cssClass += ' align-left';

    return (

      <div className={ cssClass }>

        <div className='hotspot'>
          <span>{ this.props.label }</span>
        </div>

        <nav className='dropdown'>
          { this.props.children }
        </nav>
      </div>

    );
  }
}
