/***
*
*   SIGN UP
*   Signup form for paying account owners.
*
**********/

import React from 'react';

import { StripeProvider, Elements } from 'react-stripe-elements';
import { AuthContext, ViewContext, Card, PaymentForm, Link } from 'components/lib';

const Settings = require('settings.json');

export class Signup extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,

      signup: {
        name: {
          label: 'Name',
          value: '',
          type: 'text',
          required: true,
          errorMessage: 'Please enter your first name'
        },
        email: {
          label: 'Email',
          value: '',
          type: 'email',
          required: true,
        },
        password: {
          label: 'Password',
          type: 'password',
          required: true
        },
        token: {
          label: 'Credit Card',
          helper: 'You won\'t be charged until you create a project',
          type: 'creditcard',
          required: true,
        }
      }
    }
  }

  render(){
    return(

      <div className='auth-form'>

        <h1>Create Your Rafters Account</h1>

        <StripeProvider apiKey={ Settings[process.env.REACT_APP_ENV].stripe.publishableAPIKey }>
          <Elements fonts={ Settings[process.env.REACT_APP_ENV].stripe.fonts }>

            <Card loading={ this.state.loading }>

            <AuthContext.Consumer>
              {(context) => {

                return (
                  <PaymentForm
                    data={ this.state.signup }
                    url='/api/account'
                    method='POST'
                    buttonText='Create Account'
                    callback={ context.signin }
                  />
                );
              }}
            </AuthContext.Consumer>

            <span>Already registered? <Link url='/signin' text='Sign In' /></span>

            </Card>

          </Elements>
        </StripeProvider>

      </div>
    );
  }
}

Signup.contextType = ViewContext;
