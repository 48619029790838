/***
*
*   BILLING
*   Enables an account holder to change their subscription
*   plan or update their card details.
*
**********/

import React from 'react';
import Axios from 'axios';

import { StripeProvider, Elements } from 'react-stripe-elements';
import { ViewContext, Header, Card, AccountNav, TabView, Loader, Message, PaymentForm } from "components/lib";

const Settings = require('settings.json');

export class Billing extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,

      message: {
        show: false,
        title: '',
        text: '',
        type: ''
      },

      plan: null,

      billing: {
        plan: {
          label: 'Your subscription plan',
          type: 'radio',
          class: 'selector-bar',
          options: []
        }
      },
      card: {
        token: {
          label: 'Update Your Card',
          type: 'creditcard',
          required: true,
        }
      }
    }
  }

  async componentDidMount(){
    window.analytics.page('Billing'); //segment analytics event
    try {

      this.setState({ loading: true });
      await this.processSubscription();
      this.setState({ loading: false });

    }
    catch (err){

      this.context.handleError(err);

    }
  }

  async processSubscription(){

    // check the subscription state for the account
    // display an error message if the latest payment has failed
    // or the payment requires 2-factor authentication
    const res = await Axios.get('/api/account/subscription');
    const subscription = res.data.subscription;

    if (subscription){

      this.setState({
        plan: {

          projects: subscription.quantity,
          price: subscription.total,

        }
      })

      switch (subscription.status){

        case 'succeeded':
        this.activateSubscription();
        this.setState({

          message: {

            show: false,
            title: '',
            text: '',
            type: '',

          }
        });
        break;

        case 'requires_payment_method':
        this.setState({

          message: {

            show: true,
            title: 'Your latest payment failed',
            text: 'Our latest attempt to charge your card failed. Please update your card details below, and we will attempt to charge your card again.',
            type: 'error'

          }
        });
        break;

        case 'requires_action':
        this.setState({

          message: {

            show: true,
            title: 'You need to authorize your payment',
            text: 'In order to process your latest subscription payment, you need to authorize the transaction with your bank. Please check your email for a link to do this, then refresh this page when you\'re done.',
            type: 'warning'

          }
        });
        break;

        default:
        this.setState({

          message: {

            show: false,
            title: '',
            text: '',
            type: '',

          }
        });
        break;

      }

    }
  }

  activateSubscription(){

    // activate the users subscription in localStorage
    // so the user may access other pages
    let user = JSON.parse(localStorage.getItem('user'));
    user.subscription = true;
    localStorage.setItem('user', JSON.stringify(user));

  }

  render(){
    return (

        <>
        <Header title='Your Account' />
        <AccountNav/>

        { this.state.message.show &&
          <Message
            title={ this.state.message.title }
            text={ this.state.message.text }
            type={ this.state.message.type }
          />
        }

        { this.state.loading ? <Loader text='loading'/> :

          <>
          <TabView name='Billing' labels={['Plan', 'Card Details']}>

            <Card loading={ this.state.loading }>


              { this.state.plan &&
                <Message
                  title='Your Plan'
                  text={

                    'Your card will be charged $' + this.state.plan.price + ' ' +
                    'each month based on your ' + this.state.plan.projects + ' projects.'

                  }
                  type='info'
                />
              }
            </Card>

            <Card loading={ this.state.loading }>
              <StripeProvider apiKey={ Settings[process.env.NODE_ENV].stripe.publishableAPIKey }>
                <Elements>
                  <PaymentForm
                    className='restrict-width'
                    data={ this.state.card }
                    url='/api/account/card'
                    method='PUT'
                    buttonText='Save Card'
                    callback={ event => this.processSubscription() }
                  />
                </Elements>
              </StripeProvider>
            </Card>
          </TabView>
          </>
        }
        </>

    );
  }
}

Billing.contextType = ViewContext;
