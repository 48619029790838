/***
*
*   FORGOT PASSWORD
*   Triggers a password reset process based on the supplied email.
*
**********/

import React from 'react';
import { Form, Card } from 'components/lib';

export class ForgotPassword extends React.Component {

  constructor(props){

    super(props);

    this.passwordReset = {
      email: {
        label: 'Email',
        type: 'email',
        required: true
      }
    }
  }

  render(){
    return(

      <>
      <h1>Reset Your Password</h1>

      <Card>

        <p>Enter your email address and we'll send you
        instructions to reset your password</p>

        <Form
          data={ this.passwordReset }
          url='/api/user/resetpassword'
          method='POST'
          buttonText='Reset Password'
        />

      </Card>
      </>
    );
  }
}
