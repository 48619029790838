/***
*
*   MASTER DASHBOARD
*   Manage all users signed up to your application.
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Card, Table, DeleteTableRow, UpdateTableRow,
  Actions, IconButton } from 'components/lib';

export class MasterUsers extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,

      users: {
        header: null,
        body: null
      },
    }

    this.editUserForm = {
      id: {
        type: 'hidden'
      },
      account: {
        type: 'hidden'
      },
      name: {
        label: 'Name',
        type: 'text',
        requried: true,
      },
      email: {
        label: 'Email',
        type: 'email',
        required: true
      },
      permission: {
        label: 'Permission',
        type: 'select',
        options: [],
        required: true,
      }
    };

    this.deleteUserForm = {
      id: {
        type: 'hidden',
        value: null
      },
      account: {
        type: 'hidden',
        value: null
      }
    };

    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.contact = this.contact.bind(this);

  }

  async componentDidMount(){

    try {

      this.setState({ loading: true });

      const res1 = await Axios.get('/api/master/users');
      const res2 = await Axios.get('/api/user/permissions');

      // format the data for the view
      res2.data.permissions.forEach(permission => {

        this.editUserForm.permission.options.push({
          value: permission, name: permission
        });
      });

      this.setState({

        loading: false,
        users: {
          ...this.state.users,
          body: res1.data.users
        }

      });
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  edit(data){

    // populate the data;
    for (let key in this.editUserForm)
      this.editUserForm[key].value = data[key];

    this.editUserForm.permission.default = data.permission;

    this.context.showModal({

      title: 'Edit User',
      form: this.editUserForm,
      buttonText: 'Save',
      url: '/api/master/user',
      method: 'PUT'

    }, (res) => {

      this.context.showNotification(data.name + ' was updated', 'success', true);
      UpdateTableRow(this.state.users.body, res, data.id);

    });
  }

  delete(data){

    this.deleteUserForm.id.value = data.id;
    this.deleteUserForm.account.value = data.account;

    this.context.showModal({

      title: 'Delete User',
      form: this.deleteUserForm,
      buttonText: 'Delete',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: '/api/master/user',
      method: 'DELETE'

    }, () => {

      this.context.showNotification(data.name + ' was deleted', 'success', true);
      DeleteTableRow(this.state.users.body, data.id);

    });
  }

  contact(data){

    window.location = 'mailto:' + data.email;

  }

  render(){

    return(

      <>
      <Card loading={ this.state.loading }>

        <Table
          data={ this.state.users }
          loading={ this.state.loading }
          showIds={ false }
          badge={{ col: 'permission', color: 'blue' }}>

        <Actions>
          <IconButton image='trash' action={ this.delete }/>
          <IconButton image='edit' action={ this.edit }/>
          <IconButton image='mail' action={ this.contact }/>
        </Actions>

        </Table>
      </Card>
      </>
    );
  }
}

MasterUsers.contextType = ViewContext;
