/***
*
*   CONTACTS
*   Lists the contacts for the current project
*
**********/

import React from 'react';
import Axios from 'axios';
import { ViewContext, Header, Card, Button, Table, Actions, IconButton,
  UpdateTableRow, DeleteTableRow, ResetModalForm,
  ProjectNav, Loader, BlankSlateMessage } from 'components/lib';

export class Contacts extends React.Component {

  constructor(props){

    super(props);

    const url = window.location.href;
    this.slug = url.substring(url.indexOf('projects/')+9, url.indexOf('/contacts'));

    this.state = {

      loading: false,
      contacts: {

        header: null,
        body: null,

      },

      project_id: localStorage.getItem('projectId'),

      groups: null,

      editContactForm: {
        first_name: {
          label: 'First name',
          type: 'text',
          required: true,
          errorMessage: 'Please provide a first name'
        },
        last_name: {
          label: 'Last name',
          type: 'text',
          required: true,
          errorMessage: 'Please provide a last name'
        },
        company: {
          label: 'Company',
          type: 'text'
        },
        phone_number: {
          label: 'Phone number',
          type: 'phone',
          required: true,
          errorMessage: 'Please enter a valid phone number e.g. 14151231234'
        },
        groups: {
          label: 'Groups',
          type: 'multiselect',
          options: [],
          errorMessage: 'Please select at least one group'
        },
        id: {
          type: 'hidden',
          id: null,
        },
        project_id: {
          type: 'hidden',
        }
      }
    }

    this.deleteContactForm = {
      id: {
        type: 'hidden',
        value: null
      }
    }

    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);

  }

  async componentDidMount(){
    window.analytics.page('Contacts'); //segment analytics event
    try {

      let groups = [];
      this.setState({ loading: true });

      const res1 = await Axios.get('/api/project/' + this.slug + '/contacts');
      const res2 = await Axios.get('/api/project/' + this.slug + '/groups');

      const len = res2.data.groups.length;
      for (let i = 0; i < len; i++){
        groups.push({

          value: res2.data.groups[i].id,
          label: res2.data.groups[i].name

        });
      }

      this.setState({

        loading: false,
        contacts: {

          ...this.state.contacts,
          body: res1.data.contacts

        },

        groups: res2.data.groups,
        editContactForm: {
          ...this.state.editContactForm,
          groups: {
            ...this.state.editContactForm.groups,
            options: groups
          }
        }
      });
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  create(){

    ResetModalForm(this.state.editContactForm);

    this.setState({

      editContactForm: {
        ...this.state.editContactForm,
        project_id: {
          type: 'hidden',
          value: this.state.project_id
        }
      }
    }, () => {

      this.context.showModal({

        title: 'Create Contact',
        form: this.state.editContactForm,
        buttonText: 'Create',
        url: '/api/contact',
        method: 'POST'

      }, (form, response) => {

        let rows = this.state.contacts.body;
        rows.unshift(response.contact);

        this.setState({
          contacts: {
            ...this.state.contacts,
            body: rows
          }
        });

        this.context.showNotification(

          response.contact.first_name + ' ' +
          response.contact.last_name + ' was created', 'success', true

        );
      });
    });
  }

  edit(data){

    let state = Object.assign({}, this.state);
    state.editContactForm.id.value = data.id;

    // populate the editor form
    for (let key in state.editContactForm){
      if (key !== 'project_id'){

        state.editContactForm[key].value = data[key]

      }
    }

    // get the selected groups
    // use the groups state object rather than making a server call
    if (data.groups){

      let groupsArr = data.groups.split(', ');
      let selectedGroups = [];

      for (let i = 0; i < groupsArr.length; i++){

        let g = state.groups.find(x => x.name === groupsArr[i]);
        selectedGroups.push({ value: g.id, label: g.name });

      }

      state.editContactForm.groups.default = selectedGroups;

    }

    this.setState(state, () => {

      this.context.showModal({

        title: 'Update Contact',
        form: this.state.editContactForm,
        buttonText: 'Update',
        url: '/api/contact',
        method: 'PUT'

      }, (form, response) => {

        UpdateTableRow(this.state.contacts.body, response.contact, data.id);
        this.context.showNotification(

          response.contact.first_name + ' ' +
          response.contact.last_name + ' was updated',
          'success', true

        );
      });
    });
  }

  delete(data){

    // delete the contact
    this.deleteContactForm.id.value = data.id

    this.context.showModal({

      title: 'Delete Contact',
      form: this.deleteContactForm,
      buttonText: 'Delete',
      text: 'Are you sure you want to delete ' + data.first_name + ' ' + data.last_name + '?',
      url: '/api/contact',
      method: 'DELETE'

    }, () => {

      DeleteTableRow(this.state.contacts.body, data.id);

      this.context.showNotification(

        data.first_name + ' ' +
        data.last_name + ' was deleted',
        'success', true

      );
    });
  }

  

  render(){
    return(

      <React.Fragment>

        <Header title={ this.slug + ' / ' + 'Contacts'} >
          <Button text='New Contact' action={ this.create } icon='user' />
        </Header>

        <ProjectNav />

        { this.state.loading ?

          <Loader text='Loading contacts' /> :

          <React.Fragment>
          { this.state.contacts.body &&
            this.state.contacts.body.length ?

            <Card>
              
              <Table data={ this.state.contacts } search='true' >

                <Actions>
                  <IconButton image='edit' color='dark' action={ this.edit }/>
                  <IconButton image='trash' color='dark' action={ this.delete }/>
                </Actions>

              </Table>
            </Card>

            :

            <BlankSlateMessage
              title='Create Your First Contact'
              text={ "You don't have any contacts yet, would you like to create one?" }
              buttonText='Create Contact'
              action={ this.create }
            />

        }
        </React.Fragment>
      }
      </React.Fragment>
    );
  }
}

Contacts.contextType = ViewContext;
