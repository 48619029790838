/***
*
*   LOADER
*   Infinite spinning animation for loading states.
*
**********/

import React from 'react';
import './loader.scss';

export class Loader extends React.Component {

  render(){

    return (

      <div className="spinner">
        <div className='animation'></div>
        { this.props.text &&
          <div className='text'>{ this.props.text }</div>
        }
      </div>

    );
  }
}
