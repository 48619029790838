import React from 'react';

export class Label extends React.Component {
  render(){

    let cssClass = '';
    if (this.props.required) cssClass += 'required';
    if (this.props.className) cssClass += ' ' + this.props.className;

    return(

      <React.Fragment>

        <label className={ cssClass !== '' ? cssClass : undefined } htmlFor={ this.props.for }>
          { this.props.text }
        </label>

        { this.props.helper && <span className='helper'>{ this.props.helper }</span> }

      </React.Fragment>
    );
  }
}
