/***
*
*   LOGO
*   Replace the image in /images with your own logo.
*
**********/

import React from 'react';

import { Link } from 'components/lib';
import LogoImage from './images/rafters-logo.svg';
import './logo.scss';

export class Logo extends React.Component {

  render(){
    return(

      <Link url="/projects" className="logo">
        <img src={ LogoImage } alt="Logo" />
      </Link>

    )
  }
}
