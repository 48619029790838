/***
*
*   SIGN UP (user)
*   Signup form for sub users invited by an account admin.
*
**********/

import React from 'react';
import { AuthContext, ViewContext, Card, Form } from 'components/lib';

export class SignupUser extends React.Component {

  constructor(props){

    super(props);

    this.signupForm =  {
      name: {
        label: 'First Name',
        value: '',
        type: 'text',
        required: true,
        errorMessage: 'Please enter your first name'
      },
      email: {
        label: 'Email',
        value: '',
        type: 'email',
        required: true,
      },
      password: {
        label: 'Password',
        type: 'password',
        required: false
      },
      inviteId: {
        type: 'hidden',
        value: window.location.hash.substring(1)
      },
    }
  }

  render(){
    return(

      <>
      <h1>Create Your Account</h1>

      <Card>
        <AuthContext.Consumer>
          {(context) => {

            return <Form
              data={ this.signupForm }
              url='/api/user'
              method='POST'
              redirect='/dashboard'
              buttonText='Create Account'
              callback={ context.signin }
            />

          }}
        </AuthContext.Consumer>

        <span>Already registered? <a href="/signin">Sign In</a></span>

      </Card>
      </>
    );
  }
}

SignupUser.contextType = ViewContext;
