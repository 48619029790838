import React from 'react';

import { Label } from '../label/label';
import { Error } from '../error/error';

import './input.scss';

export class NumberInput extends React.Component {

  constructor(props){

    super(props);

    this.defaulterrorMessage = "Please enter a number";
    this.validate = this.validate.bind(this);

  }

  validate(event, callback){

    const min = this.props.min;
    const max = this.props.max;
    let value, valid = undefined;

    event ? value = event.target.value : value = '';

    // input is required and value is blank
    if (this.props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!this.props.required && value)
      valid = false;

    // now test for a valid number
    if (isNaN(value)){

      valid = false;
      this.errorMessage = "Please enter a valid number";

    }

    // check for min max
    if (min && max){
      if (value >= min && value <= max){
        valid = true;
      }
      else {
        valid = false;
        this.defaultErrorMessage = "Please enter a number between " + min + " and " + max;
      }
    }
    else if (min){
      if (value > min){
        valid = true;
      }
      else {
        valid = false;
        this.defaultErrorMessage = "Please enter a number above " + min;
      }
    }
    else if (max){
      if (value < max){
        valid = true;
      }
      else {
        valid = false;
        this.defaultErrorMessage = "Please enter a number below " + max;
      }
    }

    // update the parent form
    callback(this.props.name, value, valid);

  }

  render(){

    let cssClass = 'textbox';
    let showError = false;

    if (this.props.valid === false) showError = true;
    if (this.props.valid === true) cssClass += ' success'
    if (this.props.className) cssClass += ' ' + this.props.className;

    return(

      <>
      <Label
        text={ this.props.label }
        required={ this.props.required }
        for={ this.props.name }
      />

      <input
        type='number'
        id={ this.props.name }
        name={ this.props.name }
        className={ showError ? cssClass + ' error' : cssClass }
        value={ this.props.value }
        min={ this.props.min }
        max={ this.props.max }
        placeholder={ this.props.placeholder }
        onChange={ event => this.props.onChange(this.props.name, event.target.value, undefined) }
        onBlur={ event => this.validate(event, this.props.onChange) }
      />

      { showError && <Error message={ this.props.errorMessage ?
        this.props.errorMessage : this.defaultErrorMessage }/>
      }

      </>

    );
  }
}
