/***
*
*   MASTER ACCOUNTS
*   Manage all accounts signed up to your application.
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Card, Table, DeleteTableRow, UpdateTableRow,
  Actions, IconButton } from 'components/lib';

export class MasterAccounts extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,

    }

    this.editAccountForm = {
      id: {
        type: 'hidden'
      },
      email: {
        label: 'Email',
        type: 'email',
        required: true
      },
      plan: {
        label: 'Plan',
        type: 'select',
        options: [],
        required: true,
      },
      active: {
        label: 'Status',
        type: 'select',
        options: [
          { value: 1, name: 'Active' },
          { value: 0, name: 'Cancelled' }
        ],
        required: true
      }
    };

    this.deleteAccountForm = {
      id: {
        type: 'hidden',
        value: null
      }
    };

    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.contact = this.contact.bind(this);

  }

  async componentDidMount(){

    try {

      this.setState({ loading: true });

      const res1 = await Axios.get('/api/master/accounts');
      const res2 = await Axios.get('/api/account/plans');

      // // format the data for the view
      res2.data.plans.forEach(plan => {

        let label = plan.name + ' (' + res2.data.currency +
        plan.price + ' /' + res2.data.billingInterval + ')';
        this.editAccountForm.plan.options.push({ value: plan.id, name: label });

      });

      this.setState({

        loading: false,

        accounts: {
          ...this.state.accounts,
          body: res1.data.accounts,
        }
      });
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  edit(data){

    // populate the data;
    for (let key in this.editAccountForm)
      this.editAccountForm[key].value = data[key]

    this.editAccountForm.active.default = data.active;
    this.editAccountForm.plan.default = data.plan;

    this.context.showModal({

      title: 'Edit Account',
      form: this.editAccountForm,
      buttonText: 'Save',
      url: '/api/master/account',
      method: 'PUT'

    }, (res) => {

      this.context.showNotification(data.name + ' was updated', 'success', true);
      UpdateTableRow(this.state.accounts.body, res, data.id);

    });
  }

  delete(data){

    this.deleteAccountForm.id.value = data.id

    this.context.showModal({

      title: 'Delete Account',
      form: this.deleteAccountForm,
      buttonText: 'Delete Account',
      text: 'Are you sure you want to delete ' + data.name + '\'s account?',
      url: '/api/master/account',
      method: 'DELETE'

    }, () => {

      this.context.showNotification(data.name + ' was deleted', 'success', true);
      DeleteTableRow(this.state.accounts.body, data.id);

    });
  }

  contact(data){

    window.location = 'mailto:' + data.email;

  }

  render(){

    return(

      <>
      <Card loading={ this.state.loading }>

        <Table
          data={ this.state.accounts }
          showIds={ false }
          badge={{ col: 'plan', color: 'blue' }}>

        <Actions>
          <IconButton image='trash' color='dark' action={ this.delete }/>
          <IconButton image='edit' color='dark' action={ this.edit }/>
          <IconButton image='mail' color='dark' action={ this.contact }/>
        </Actions>

        </Table>
      </Card>
      </>
    );
  }
}

MasterAccounts.contextType = ViewContext;
