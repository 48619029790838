/***
*
*   MASTER DASHBOARD
*   See an overview of your entire application and its usage.
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Card, Chart, Grid, Stat } from 'components/lib';

export class MasterDashboard extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,
      stats: null

    }
  }

  async componentDidMount(){

    try {

      this.setState({ loading: true });
      const res = await Axios.get('/api/metrics/accounts');
      this.setState({ loading: false, stats: res.data.metrics });

    }
    catch (err){

      this.context.handleError(err);

    }
  }

  render(){
    return(

      <React.Fragment>
        <Grid cols='3'>
          <Stat
            loading={ this.state.loading }
            value={ this.state.stats && this.state.stats.totalAccounts }
            label='Accounts'
          />
          <Stat
            loading={ this.state.loading }
            value={ this.state.stats && this.state.stats.activeAccounts }
            label='Active'
          />
          <Stat
            loading={ this.state.loading }
            value={ this.state.stats && this.state.stats.churnedAccounts }
            label='Churned'
          />
        </Grid>

        <Card title='User Growth'>
          <Chart
            loading={ this.state.loading }
            type='line'
            data='/api/metrics/accounts/growth'
            object='growth'
            color='green'
          />
        </Card>
      </React.Fragment>
    );
  }
}

MasterDashboard.contextType = ViewContext;
