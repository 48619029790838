import React from 'react';
import { Link } from 'components/lib';
import './external.scss';

export class NotFound extends React.Component {

  render(){
    return(
      <div class="message">
        <h1 class="large">404</h1>
        <p>Sorry, we couldn't find that page</p>
        <Link url='/projects' className='btn yellow' text='Back to Projects' />
      </div>
    );
  }
}
