/***
*
*   CARD
*   Universal container for grouping UI components together.
*
*   PROPS
*   title: title string (optional)
*   loading: true/false to toggle the loading animation (optional)
*   className: apply a custom css class (optional)
*
**********/

import React from 'react';

import { Loader, Button } from 'components/lib';
import './card.scss';

export class Card extends React.Component {

  render(){

    let cssClass = 'card'
    if (this.props.loading) cssClass += ' loading';
    if (this.props.className) cssClass += ' ' + this.props.className;

    return (
      <section className={ cssClass }>

        { this.props.title &&
          <header>
            <h1>{ this.props.title }</h1>
          </header>
        }

        { this.props.loading ? <Loader /> :
          this.props.children
        }

      </section>
    );
  }
}
