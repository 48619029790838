/***
*
*   APP NAV
*   The primary navigation used inside the main application.
*
*   PROPS
*   type: fixed or slideout
*   items: array of objects containing label and link keys
*   hasSubNav: true/false – used for routing sub routes eg. /account/billing
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext, Logo, Button, IconButton } from 'components/lib';
import './app.scss'

export class AppNav extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      open: false,
      year: new Date().getFullYear(),

    }

    this.toggle = this.toggle.bind(this);

  }

  toggle(){

    if (this.state.open) document.body.classList.remove('no-scroll');
    else document.body.classList.add('no-scroll');
    this.setState({ open: this.state.open ? false : true });

  }

  render(){

    let cssClass = 'sidebar';
    if (this.props.type) cssClass += ' mobile-' + this.props.type;
    if (this.state.open) cssClass += ' open'

    return(
      <nav className={ cssClass }>

        <section className='logo-wrapper'>
          <Logo />
        </section>

        <IconButton
          image={ this.state.open ? 'x' : 'menu' }
          color={ this.state.open ? 'dark' : 'light' }
          className='btn-togglenav'
          action={ this.toggle }
        />

        <section className='nav-links'>

          { this.props.items &&
            this.props.items.map((item, index) => {

              if (item.hasSubNav){
                return (
                  <NavLink
                    key={ item.label }
                    to={ item.link }
                    style={{ width: (100/this.props.items.length) + '%' }}>

                  { item.label }

                  </NavLink>
                );
              }
              else {
                return (
                  <NavLink
                    key={ item.label }
                    exact to={ item.link }
                    style={{ width: (100/this.props.items.length) + '%' }}>

                  { item.label }

                  </NavLink>
                );
              }
          })}
        </section>

        <section className='support'>

          <h2>Support</h2>

            <a href='mailto:support@rafters.app'>Contact Us</a>
            <a href='https://intercom.help/rafters/en/'>Knowledge Base</a>

        </section>

        <section className='support'>

        <h2>Legal</h2>

          <a href='https://www.iubenda.com/privacy-policy/78277502/full-legal'>Privacy Policy</a> 
          <a href='https://www.iubenda.com/privacy-policy/78277502/cookie-policy'>Cookie Policy</a> 
          <a href='https://www.iubenda.com/terms-and-conditions/78277502'>Terms and Conditions</a> 

        </section>

        <footer>

          <Button className='btn-text' text='Sign Out' action={ this.context.signout } />
          <span>Copyright &copy; Rafters { this.state.year }</span>

        </footer>
      </nav>
    );
  }
}

AppNav.contextType = AuthContext;
