import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute, AuthProvider } from './auth';
import { History, View } from 'components/lib';

// auth views
import { Signup } from 'views/auth/signup';
import { SignupUser } from 'views/auth/signup-user';
import { Signin } from 'views/auth/signin';
import { ForgotPassword } from 'views/auth/forgot-password';
import { ResetPassword } from 'views/auth/reset-password';

// app views
import { Projects } from 'views/project/projects';
import { Groups } from 'views/project/groups';
import { Contacts } from 'views/project/contacts';
import { Notifications } from 'views/project/notifications';

import { Profile } from 'views/account/profile';
import { Password } from 'views/account/password';
import { Billing } from 'views/account/billing';
import { Users } from 'views/account/users';
import { NotFound } from 'views/external/404';

// master views
import { MasterDashboard } from 'views/master/dashboard';
import { MasterAccounts } from 'views/master/accounts';
import { MasterUsers } from 'views/master/users';

export default class App extends React.Component {

  constructor(props){

    super(props);

    // add auth token to api header calls
    const user = JSON.parse(localStorage.getItem('user'));

    if (user){
      if (user.token){

        Axios.defaults.headers.common['Authorization'] = "Bearer " + user.token;

      }
    }
  }

  render(){
    return(
      <AuthProvider>
        <Router history={ History }>
          <Switch>

            { /* auth routes */ }

            <Route exact path="/" render={() => (<Redirect to="/projects"/>)}/>

            <Route exact path='/signup'
              render={() => <View display={ Signup } layout='auth'
              title='Rafters Sign up' />}/>

            <Route exact path='/signup/user'
              render={() => <View display={ SignupUser } layout='auth'
              title='Rafters Sign up' />}/>

            <Route exact path='/signin'
              render={() => <View display={ Signin } layout='auth'
              title='Rafters Sign in' />}/>

            <Route exact path='/forgotpassword'
              render={() => <View display={ ForgotPassword } layout='auth'
              title='Forgot Your Password?' />}/>

            <Route path='/user/resetpassword'
              render={() => <View display={ ResetPassword } layout='external'
              title='Reset Your Password' />}/>


            { /* app routes */ }

            <PrivateRoute exact path='/projects' permission='user'
              render={() => <View display={ Projects } layout='app'
              title='Projects' />}/>

            <PrivateRoute exact path='/projects/:slug/notifications' permission='user'
              render={() => <View display={ Notifications } layout='app'
              title='Notifications' />}/>

            <PrivateRoute exact path='/projects/:slug/groups' permission='user'
              render={() => <View display={ Groups } layout='app'
              title='Groups' />}/>

            <PrivateRoute exact path='/projects/:slug/contacts' permission='user'
              render={() => <View display={ Contacts } layout='app'
              title='Contacts' />}/>

            <PrivateRoute exact path='/account' permission='user'
              render={() => <View display={ Profile } layout='app'
              title='Your Profile' />}/>

            <PrivateRoute exact path='/account/password' permission='user'
              render={() => <View display={ Password } layout='app'
              title='Your Password' />}/>

            <PrivateRoute exact path='/account/billing' permission='owner'
              render={() => <View display={ Billing } layout='app'
              title='Billing' />}/>

            <PrivateRoute exact path='/users' permission='admin'
              render={() => <View display={ Users } layout='app'
              title='Users' />}/>


            { /* master routes */ }

            <PrivateRoute exact path='/master' permission='master'
              render={() => <View display={ MasterDashboard } layout='master'
              title='Master Dashboard' />}/>

            <PrivateRoute exact path='/master/accounts' permission='master'
              render={() => <View display={ MasterAccounts } layout='master'
              title='Accounts' />}/>

            <PrivateRoute exact path='/master/users' permission='master'
              render={() => <View display={ MasterUsers } layout='master'
              title='Users' />}/>


            { /* 404 */ }

            <Route render={() => <View display={ NotFound }
              layout='external' title="404 Not Found" />}/>

          </Switch>
        </Router>
      </AuthProvider>
    );
  }
}
