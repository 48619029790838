import React from 'react';

import { CardElement } from 'react-stripe-elements';
import { Label } from '../label/label';
import { Error } from '../error/error';

import './input.scss';

export class CardInput extends React.Component {

  constructor(props){

    super(props);
    this.defaultErrorMessage = 'Please provide valid credit card details';

  }

  render(){

    let cssClass = 'creditcard';
    let showError = false;

    if (this.props.valid === false) showError = true;
    if (this.props.valid === true) cssClass += ' success'
    if (this.props.className) cssClass = this.props.className + ' ' + cssClass;

    return(

      <>

      <Label
        text={ this.props.label }
        required={ this.props.required }
        for={ this.props.name }
        helper={ this.props.helper }
      />

      <CardElement
        className={ showError ? cssClass + ' error' : cssClass }
        style={{
          base: { fontFamily: 'source sans pro', fontSize: '15px' }
        }}
      />

      { showError && <Error message={ this.props.errorMessage ?
        this.props.errorMessage : this.defaultErrorMessage }/>
      }

      </>
    );
  }
}
