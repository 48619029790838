/***
*
*   ICON BUTTON
*   Clickable icon button with callback.
*
*   PROPS
*   action: callback function executed on click
*   color: dark/light (default: dark)
*   params: object passed to the callback function (optional)
*   image: icon to show (refer to docs.usegravity.app/ui/icon)
*   className: apply a custom css class (optional)
*
**********/

import React from 'react';
import { Icon } from 'components/lib';

export class IconButton extends React.Component {

  render(){

    let cssClass = this.props.className ? 'ico-btn ' + this.props.className : 'ico-btn';

    return(

      <button
        title={ this.props.title }
        className={ cssClass }
        onClick={
          event => {

            event.preventDefault();
            event.stopPropagation();
            this.props.action &&
            this.props.action(this.props.params)

          }
        }>

        <Icon
          image={ this.props.image }
          color={ this.props.color }
          size={ this.props.size }
        />

      </button>

    );
  }
}
