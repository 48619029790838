/***
*
*   NOTIFICATIONS
*   Lists the notifications for the current project
*
**********/

import React from 'react';
import Axios from 'axios';
import { ViewContext, Header, Card, Button, Table,
  ProjectNav, Loader, BlankSlateMessage } from 'components/lib';
import NotificationModal from './notifications-modal';


export class Notifications extends React.Component {

  constructor(props){

    super(props);

    const url = window.location.href;
    this.slug = url.substring(url.indexOf('projects/')+9, url.indexOf('/notifications'));

    this.state = {

      loading: false,
      notifications: {

        header: null,
        body: []

      }
    }

    this.create = this.create.bind(this);
    this.refresh = this.refresh.bind(this);

  }

  componentDidMount(){

    this.setState({ loading: true });
    this.refresh();
    window.analytics.page('Notifications'); //segment analytics event

  }

  async refresh(){

    try {

      // get the notification
      const res = await Axios.get('/api/project/' + this.slug + '/notifications');

      this.setState({

        loading: false,
        notifications: {

          ...this.state.notifications,
          body: res.data.notifications

        }
      });
    }
    catch(err){

      this.context.handleError(err);

    }
  }

  create(){

    this.context.showModal({

      title: 'Create Notification',
      loading: this.state.loading,
      component: NotificationModal,
      class: 'min-height',

    }, (form, response) => {

      let notifications = this.state.notifications.body;
      notifications.push(response);

      this.setState({

        notifications: {
          ...this.state.notifications,
          body: notifications
        }
      });

      // refresh the UI after 30 seconds
      setTimeout(this.refresh, 5000);

    });
  }

  render(){

    return(

      <React.Fragment>

        <Header title={ this.slug + ' / ' + 'Notifications'} >
          <Button text='New Notification' action={ this.create } icon='message-square' />
        </Header>
        
        <ProjectNav />

        { this.state.loading ?

        <Loader text='loading notifications' /> :

        <React.Fragment>
        { this.state.notifications.body &&
          this.state.notifications.body.length ?

          <Card>
            <Table
              data={ this.state.notifications }
              badge={{ col: 'status', color: 'blue' }}
            >
            </Table>
          </Card>

          :

          <BlankSlateMessage
            title='Create a Notification'
            text="You haven't created any notifications yet. Would you like to create one now?"
            buttonText='Create Notification'
            action={ this.create }
          />

        }
        </React.Fragment>
      }
      </React.Fragment>
    );
  }
}

Notifications.contextType = ViewContext;
