/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value.
*
*   PROPS
*   label: string
*   loading: true/false to toggle loading animation (optional)
*   value:  numeric value
*   icon: icon image to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import { Icon, Loader } from 'components/lib';
import './stat.scss';

export class Stat extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      change: this.props.change,
      changeDirection: null,

    }
  }

  getChangeDirection(){

    if (this.props.change){

      if (this.props.change.toString().includes('-'))
        return 'down';
      else
        return 'up';

    }
  }

  render(){

    let cssClass = 'stat';
    if (this.props.change){

      if (this.props.change.increase) cssClass += ' up';
      if (!this.props.change.increase) cssClass += ' down';

    }

    if (this.props.loading){
      return (
        <div className={ cssClass }>
          <Loader />
        </div>
      );
    }

    return(

      <div className={ cssClass }>

        { this.props.icon &&
          <Icon color="dark" image={ this.props.icon } size='20' />
        }

        <div className="label">{ this.props.label }</div>
        <div className="value">{ this.props.value }</div>

        { this.props.change &&
          <div className={ 'change' }>
          { this.props.change.percentage + ' monthly ' +
            (this.props.change.increase ? 'increase' : 'decrease')
          }
          </div>
        }

      </div>

    );
  }
}
