/***
*
*   PROJECTS
*   List all available projects
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Header, Card, Button, Actions, IconButton,
  Table, DeleteTableRow, UpdateTableRow, ResetModalForm,
  Stat, Loader, BlankSlateMessage } from "components/lib";

import './projects.scss';

export class Projects extends React.Component {

  constructor(props){

    super(props);

    this.state = {

      loading: false,
      projects: {

        header: null,
        body: []

      },

      metrics: {
        data: null,
        loading: false,
      },
    }

    this.editProjectForm = {
      name: {
        label: 'Project Name',
        type: 'text',
        required: true,
        errorMessage: 'Please provide a project name'
      },
      description: {
        label: 'Description',
        type: 'text',
        required: false,
      },
      location: {
        label: 'Location',
        type: 'text',
        required: false,
        errorMessage: 'Please provide a location'
      },
      id: {
        type: 'hidden',
        value: null
      }
    }

    this.deleteProjectForm = {
      id: {
        type: 'hidden',
        value: null
      }
    }

    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.edit = this.edit.bind(this);
    this.getProjects = this.getProjects.bind(this);
    this.getMetrics = this.getMetrics.bind(this);

  }

  async getProjects(){

    try {

      const res = await Axios.get('/api/projects');

      if (res.data.projects){
        this.setState({

          projects: {
            ...this.state.projects.headers,
            body: res.data.projects
          },

        });
      }
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  async getMetrics(){

    try {

      this.setState({
        metrics: {
          ...this.state.metrics,
          loading: true,
        }
      });

      const res = await Axios.get('/api/projects/metrics');

      this.setState({
        metrics: {
          data: res.data.metrics,
          loading: false
        }
      });

    }
    catch (err){

      this.context.handleError(err);

    }
  }

  async componentDidMount(){

    this.setState({ loading: true });
    await this.getProjects();
    await this.getMetrics();
    this.setState({ loading: false });
    window.analytics.page('Projects'); //segment analytics event

  }

  create(){

    ResetModalForm(this.editProjectForm);

    this.context.showModal({

      title: 'Create Project',
      form: this.editProjectForm,
      message: {

        title: 'Your plan will be updated',
        text: 'You will be charged $50 per month for this project.',
        type: 'warning',

      },
      buttonText: 'Create',
      url: '/api/project',
      method: 'POST'

    }, (form, response) => {

      let rows = this.state.projects.body;
      rows.unshift(response.project);

      this.setState({
        projects: {
          ...this.state.projects,
          body: rows
        }
      });

      this.getMetrics();
      this.context.showNotification(response.project.name[0].label + ' was created', 'success', true);
      
    });
  }

  edit(data){

    // update the project
    this.editProjectForm.id.value = data.id;
    this.editProjectForm.name.value = data.name[0].label;
    this.editProjectForm.description.value = data.name[1].label;
    this.editProjectForm.location.value = data.location;

    this.context.showModal({

      title: 'Update Project',
      form: this.editProjectForm,
      buttonText: 'Save',
      url: '/api/project',
      method: 'PUT'

    }, (form, response) => {

      this.context.showNotification(response.project.name[0].label + ' was updated', 'success', true);
      UpdateTableRow(this.state.projects.body, response.project, data.id);

    });
  }

  delete(data){

    // delete the project
    this.deleteProjectForm.id.value = data.id

    this.context.showModal({

      title: 'Delete Project',
      form: this.deleteProjectForm,
      buttonText: 'Delete Project',
      text: 'Are you sure you want to delete ' + data.name[0].label + '?',
      url: '/api/project',
      method: 'DELETE'

    }, () => {

      this.context.showNotification(data.name[0].label + ' was deleted', 'success', true);
      DeleteTableRow(this.state.projects.body, data.id);
      this.getMetrics();

    });
  }

  render(){

    return (

      <React.Fragment>
        <Header title='Projects'>
          <Button text='New Project' action={ this.create } icon='plus-square' />
        </Header>

        { this.state.loading ?

          <Loader text='loading projects' /> :

          <React.Fragment>
          { this.state.projects.body &&
            this.state.projects.body.length ?

            <React.Fragment>
              <section className='stats'>
                { Object.keys(this.state.metrics.data).map((metric) => {

                  return (
                    <Stat
                      key={ this.state.metrics.data[metric].label }
                      loading={ this.state.metrics.loading }
                      value={ this.state.metrics.data[metric].total }
                      label={ this.state.metrics.data[metric].label }
                      change={ this.state.metrics.data[metric].change &&

                        this.state.metrics.data[metric].change

                      }
                    />
                  );

                })}
              </section>

              <Card>
                <Table
                  data={ this.state.projects }
                  badge={{ col: 'status', color: 'blue' }}>

                  <Actions>
                    <IconButton image='edit' color='dark' action={ this.edit }/>
                    <IconButton image='trash' color='dark' action={ this.delete }/>
                  </Actions>

                </Table>
              </Card>
            </React.Fragment>

            :

            <BlankSlateMessage
              title='Create a Project'
              text="You haven't created any projects yet. Would you like to create one now?"
              buttonText='Create Project'
              action={ this.create }
            />

          }
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

Projects.contextType = ViewContext;
