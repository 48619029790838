/***
*
*   PROGRESS STEP BARS
*   Steps are used to indicate the current point in a multiple-stage
*   process, such as filling in a long form
*
*   PROPS
*   items: array of objects containing keys: name, url and completed (bool)
*
**********/

import React from 'react';
import './step-bar.scss';

export class ProgressStepBar extends React.Component {


  render(){

    return(

      <ol className="progress-step-bar">
        { this.props.steps &&
          this.props.steps.map((step, index) => {

          return (
            <li
              key={ index }
              className={ index <= this.props.steps.indexOf(true) ? 'complete' : 'incomplete' }>
            </li>
          );
        })}
      </ol>

    )
  }
}
