/***
*
*   AUTH LAYOUT
*   Layout for the signup/signin pages
*
**********/

import React from 'react';

import { Row, HomeNav } from 'components/lib';
import './auth.scss';
import '../layout.scss';

export class AuthLayout extends React.Component {

  render(){

    document.body.classList.add('color-bg');

    return (

      <main className='auth'>
        <HomeNav/>
        <Row>
          <this.props.children/>
        </Row>
      </main>
    );
  }
}
