/***
*
*   NOTIFICATION
*   iOS-styled messages that appear at the top of the screen.
*   Create a notification anytime using this.context.showNotification()
*   and pass the three parameters below
*
*   PROPS
*   message: string containing the message
*   type - success/error/warning
*   autoclose - if false, the notification won't disapear until clicking the X
*
**********/

import React from 'react';
import { ViewContext, IconButton } from 'components/lib';
import './notification.scss';

export class Notification extends React.Component {

  render(){

    return (
      <ViewContext.Consumer>
        {(context) => (

          <div className={ 'notification ' +
            context.notification.type + ' ' +
            context.notification.visible
          }>

          { context.notification.text }
          { !context.notification.autoclose &&

            <IconButton
              color='light'
              image='x'
              className='btn-close-notification'
              action={ context.hideNotification }
            />
          }

          </div>
        )}
      </ViewContext.Consumer>
    );
  }
}
