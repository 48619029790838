/***
*
*   VIEW
*   The view houses global components that are common to all views
*   (notification, modal), handles errors and renders the layout.
*
*   Read the full documentation: https://docs.usegravity.app/react/views
*
**********/

import React from 'react';

import { AppLayout, AuthLayout, ExternalLayout, HomeLayout,
  MasterLayout, SetupLayout, Modal, History, Notification, Loader } from "../lib";

import './scss/normalize.scss';
import './scss/view.scss';
import './scss/typography.scss';

export const ViewContext = React.createContext();

export class View extends React.Component {

  constructor(){

    super();

    this.layouts = {

      app: AppLayout,
      auth: AuthLayout,
      external: ExternalLayout,
      master: MasterLayout,
      setup: SetupLayout,
      home: HomeLayout

    }

    this.state = {

      loading: false,

      notification: {
        visible: "hide",
        autoclose: true,
      },

      modal: {
        visible: 'hide',
      }
    }

    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.finishLoading = this.finishLoading.bind(this);

  }

  componentDidMount(){

    // show notification?
    const n = JSON.parse(localStorage.getItem('notification'));

    if (n){

      this.showNotification(n.text, n.type,n.autoclose);
      localStorage.removeItem('notification');

    }
  }

  showNotification(text, type, autoclose){

    this.setState({
      notification: {

        text: text,
        type: type,
        visible: "show",
        autoclose: autoclose

      }
    });

    if (autoclose)
      setTimeout(this.hideNotification, 2000);

  }

  hideNotification(){

    this.setState({
      notification: {

        text: '',
        type: '',
        visible: '',
        autoclose: true

      }
    });
  }

  showModal(content, callback){

    let state = Object.assign({}, this.state);

    if (content){

      for (let key in content)
        state.modal[key] = content[key];

      state.modal.visible = 'show'
      state.modal.callback = callback;

    }

    this.setState(state, function(){

      state.modal.visible = 'animateIn';
      setTimeout(() => this.setState(state), 5);

    });
  }

  hideModal(cancel, response){

    let state = Object.assign({}, this.state);
    state.modal.visible = 'animateOut';
    this.setState(state);

    // callback?
    if (!cancel && this.state.modal.callback){
      this.state.modal.callback(this.state.modal.form, response);
    }

    // reset the modal
    setTimeout(() => {

      this.setState({ modal: {

        title: null,
        text: null,
        buttonText: null,
        url: null,
        method: null,
        visible: "hide",

      }
    })}, 150);
  }

  startLoading(){

    this.setState({ loading: true });

  }

  finishLoading(){

    this.setState({ loading: false });

  }

  handleError(err){

    let message = 'There was a glitch in the matrix – please try again';

    if (err){

      message = err.toString();

      if (err.response){
        if (err.response.data){
          if (err.response.data.message){

            message = err.response.data.message

          }

          if (err.response.data.status){
            switch (err.response.data.status){

              case 404:
              History.push('/notfound');
              break;

              default:
              History.push('/notfound');
              break;

            }
          }
        }
      }
    }

    this.showNotification(message, 'error', false);

  }

  render(){

    let Layout = AppLayout;
    document.title = this.props.title;

    if (this.props.layout)
      Layout = this.layouts[this.props.layout];

    const viewContext = {

      notification: this.state.notification,
      showNotification: this.showNotification,
      hideNotification: this.hideNotification,
      modal: this.state.modal,
      showModal: this.showModal,
      hideModal: this.hideModal,
      handleError: this.handleError,
      startLoading: this.startLoading,
      finishLoading: this.finishLoading

    }

    return (

      <ViewContext.Provider value={ viewContext }>

          <Notification />

          <Modal
            title={ this.state.modal.title }
            form={ this.state.modal.form }
            text={ this.state.modal.text }
            url={ this.state.modal.url }
            message={ this.state.modal.message }
            component={ this.state.modal.component }
            method={ this.state.modal.method }
            className={ this.state.modal.class }
            buttonText={ this.state.modal.buttonText }>
          </Modal>

          { this.state.loading ?

            <Loader /> :

            <Layout
              title={ this.props.title }
              loading={ this.state.loading }>
              { this.props.display }
            </Layout>

          }

      </ViewContext.Provider>
    );
  }
}
