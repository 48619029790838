/***
*
*   MODAL
*   To display an overlay modal anywhere in your application call
*   this.context.showModal({ }) with an object containing the following props
*
*   PROPS
*   title: (optional)
*   text: message to the user (optional)
*   form: a form object (optional: see form docs for more information)
*   url: destination to send the form
*   method: HTTP post type
*   buttonText – text for the confirm button
*
**********/

import React from 'react';
import { Form, ViewContext, Message, Loader } from 'components/lib';
import './modal.scss';

export class Modal extends React.Component {

  close(event, context){

    if (event.target === event.currentTarget)
      context.hideModal(true)

  }

  render(){

    let cssClass = 'modal ';
    if (this.props.className) cssClass += this.props.className + ' ';
    if (this.props.loading) cssClass += 'loading ';

    // render a standard modal
    return (
      <ViewContext.Consumer>
        {(context) => (

          <div className={ cssClass + context.modal.visible }
            onClick={(event) => this.close(event, context) }>

            { this.props.component ?

              <div className='modal-content card'>
                <this.props.component/>
              </div>

              :

              <div className='modal-content card'>

                { this.props.title &&
                  <header>
                    <h1>{ this.props.title }</h1>
                  </header>
                }

                { this.props.loading &&
                  <Loader />
                }

                { this.props.text && !this.props.loading &&
                  <p>{ this.props.text }</p>
                }

                { this.props.message && !this.props.loading &&
                  <Message
                    title={ this.props.message.title }
                    text={ this.props.message.text }
                    type={ this.props.message.type }
                  />
                }

                { this.props.form && !this.props.loading &&
                  <Form
                    method={ this.props.method }
                    url={ this.props.url }
                    data={ this.props.form }
                    buttonText={ this.props.buttonText }
                    cancel={ event => context.hideModal(true) }
                  />
                }

              </div>
            }

          </div>

        )}
      </ViewContext.Consumer>
    );
  }
}

export function ResetModalForm(form){

  Object.keys(form).map((cell) => {

    if (form[cell].value){

      form[cell].value = '';
      form[cell].default = null;

    }

    return false;

  });
}
