/***
*
*   NOTIFICATIONS MODAL
*   Custom three part modal for sending notifications
*
**********/

import React from 'react';
import Axios from 'axios';

import { ViewContext, Button, ProgressStepBar,
  Form, Message, Loader } from 'components/lib';

export default class NotificationModal extends React.Component {

  constructor(props){

    super(props);

    const url = window.location.href;
    this.slug = url.substring(url.indexOf('projects/')+9, url.indexOf('/notifications'));

    this.state = {

      loading: false,

      views: {

        visible: [true, false, false]

      },

      sendNotificationForm: {
        project: {
          type: 'hidden',
          value: localStorage.getItem('projectId')
        },
        groups: {
          label: 'Who',
          type: 'multiselect',
          placeholder: 'Select groups',
          options: [],
          required: true,
        },
        text: {
          label: 'What',
          type: 'text',
          placeholder: 'Notification Text',
          required: true,
          errorMessage: 'Please enter a message'
        },
        include_project_details: {
          type: 'checkbox',
          options: ['Include project details in notification']
        }
      },

      notification: null,
      response: null

    }

    this.projectId = localStorage.getItem('projectId');
    this.showView = this.showView.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.send = this.send.bind(this);

  }

  async componentDidMount(){

    try {

      let groups = [];
      this.setState({ loading: true });

      // get the groups
      const res = await Axios.get('/api/project/' + this.slug + '/groups');

      // format the groups
      const len = res.data.groups.length;
      for (let i = 0; i < len; i++){
        groups.push({

          value: res.data.groups[i].id,
          label: res.data.groups[i].name

        });
      }

      this.setState({

        loading: false,

        sendNotificationForm: {

          ...this.state.sendNotificationForm,

          groups: {
            ...this.state.sendNotificationForm.groups,
            options: groups
          }
        }
      });
    }
    catch (err){

      this.context.handleError(err);

    }
  }

  saveForm(data){

    this.setState({ notification: data },
      () => this.showView(1));

  }

  showView(index){

    let state = Object.assign({}, this.state);
    state.views.visible = [false, false, false];
    state.views.visible[index] = true;
    this.setState(state);

  }

  async send(){

    try {

      this.setState({ loading: true });
      const res = await Axios.post('/api/notification', this.state.notification);

      this.setState({

        loading: false,
        response: res.data.notification

      });

      this.showView(2);


    }
    catch (err){

      this.context.hideModal(true);
      this.context.handleError(err);

    }
  }

  render(){

    return(

      <>

      <header>
        <h1>Send Notification</h1>
      </header>

      { this.state.loading ? <Loader text='loading '/> :

        <>
        <section className='progress'>

          <h2>Progress</h2>
          <ProgressStepBar steps={ this.state.views.visible }/>

        </section>

        <ul className='views'>
          <li className={ this.state.views.visible[0] ? 'view' : 'view hide' }>

            <>
              { this.state.sendNotificationForm.groups.options.length ?

                <Form
                  data={ this.state.sendNotificationForm }
                  buttonText='Next'
                  callback={ this.saveForm }
                  cancel={ event => this.context.hideModal(true) }
                />

                :

                <Message
                  title='No Groups'
                  type='info'
                  text='You need to create a contacts group before you can send a notification.'
                  buttonText='Create Group'
                  buttonLink={ '/projects/' + this.slug + '/groups' }
                />
              }
              </>

          </li>
          <li className={ this.state.views.visible[1] ? 'view' : 'view hide' }>

            <h2>Who</h2>

            { this.state.notification &&
              this.state.notification.groups &&
              <div className='scroller'>

                <ul className='list'>
                  { this.state.notification.groups.map(group => {

                    return <li key={ group.label }>{ group.label }</li>

                   })
                  }
                </ul>

              </div>
            }

            { this.state.notification &&
              this.state.notification.text &&
                <section className='box'>

                  <h2>What</h2>
                  <p>{ this.state.notification.text }</p>

                </section>
            }

            <Button
              text='Edit'
              className='btn-outline'
              action={ event => this.showView(0) }
            />

            <Button
              text='Send'
              action={ event => this.send() }
            />

          </li>
          <li className={ this.state.views.visible[2] ? 'view' : 'view hide' }>

            <Message
              title='Notification sent!'
              text='Your message has been sent to your contacts'
              type='success'
            />

            <Button
              text='Close'
              action={ event => this.context.hideModal(false, this.state.response) }
            />

          </li>
        </ul>
        </>

      }
      </>
    );
  }
}

NotificationModal.contextType = ViewContext;
