/***
*
*   PASSWORD
*   Enables the user to update their password.
*
**********/

import React from 'react';
import { ViewContext, Header, Card, Form, AccountNav } from 'components/lib';

export class Password extends React.Component {

  constructor(props){

    super(props);

    this.password = {
      oldpassword: {
        label: 'Old Password',
        type: 'password',
        required: true
      },
      newpassword: {
        label: 'New Password',
        type: 'password',
        required: true
      }
    }
  }

  render(){
    return (

        <>
        <Header title='Your Account' />
        <AccountNav/>

        <Card title="Update Your Password">
          <Form
            className='restrict-width'
            url='/api/user/password'
            method='PUT'
            buttonText='Save Password'
            data={ this.password }
          />
        </Card>
        </>

    );
  }
}

Password.contextType = ViewContext;
