/***
*
*   LOADINGG BUTTON
*   Button with loading animation for actions
*   that will take some time to process.
*
*   PROPS
*   text: button label
*   action: callback function executed on click
*   params: object passed to the callback function (optional)
*   color: red/blue (default: green)
*   loading: true/false to toggle the animation
*   className: apply a custom css class (optional)
*
**********/

import React from 'react';
import { Button } from 'components/lib';

export class LoadingButton extends React.Component {

  render(){

    // create the class
    let cssClass = 'btn-loader';
    if (this.props.color) cssClass += ' '  + this.props.color;
    if (this.props.loading) cssClass += ' loading';
    if (this.props.className) cssClass += ' ' + this.props.className;

    return (

      <div className={ cssClass }>
        <Button {...this.props } />
      </div>

    )
  }
}
